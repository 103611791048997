import React from "react";
import styled from "styled-components";
import { AspectRatio } from "react-aspect-ratio";


const Container = styled.div`
  background-color: #f3f3ed;
  position: relative;
  transition: all 1s ease-in-out;
  pointer-events: none;
  &.hover {
    .blurb {
      opacity: 1;
    }
  }
`;

const Inner = styled.div`
  transition: all 1s ease-in-out;
  background-image: url("${(props) => props.bg}");
  background-size: cover;
  will-change: border-radius;
  background-position: center center;
  a {
    pointer-events: auto;
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBzZiPVYMwEMa/doKOcBvoBs0GdgPZQDcoGzhCu4E6QdsJ1Alkg7KB3geXFvoHQwng773vPQgpd7lckisThDNTOdPc7sWe5arM9Kba2XU0aGyp2qs2qifVfcUB34dtiWptfVfW1hka/1alZqgNif2WzghuQFQfKEcu6EZqzrSKiuA4+lg4e+djSGexzs+Ij9i73V8dY0fgFE7JHg1TzCRcoX8Y5c2lB6L6QfckDIVOnOXHWvWC4XAoV1+NxnnqiY05g6lqofpE5G02gHezXTjhrGFotqoHf3MIywgwDWa1ixEotvOpOZBjHDLan+IfQCcYhbGm40DrYzYiRT4yEruRnBCUs5DTCW5UcwyPU335G+bDGMv07BBjQx+FzDUEZS7WcNY4VDTWuFI8vWKY49zhQhQ8MwTWgB2QEBt82FdtwUGykElDOieIv4FJGwc8C3Nkie44dKjiBeXSvTVPBOUqiJJnib2I8n+GmwyzP51nbqUIWPYThOPMwJ0Zy3CsQ6qfCfhpgOXiFoF1ShsnqnijfpT5iVOt+AUVF15WYVqPMgAAAABJRU5ErkJggg=="), auto;
    &:active {
      cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBzZiPVYMwEMa/doKOcBvoBs0GdgPZQDcoGzhCu4E6QdsJ1Alkg7KB3geXFvoHQwng773vPQgpd7lckisThDNTOdPc7sWe5arM9Kba2XU0aGyp2qs2qifVfcUB34dtiWptfVfW1hka/1alZqgNif2WzghuQFQfKEcu6EZqzrSKiuA4+lg4e+djSGexzs+Ij9i73V8dY0fgFE7JHg1TzCRcoX8Y5c2lB6L6QfckDIVOnOXHWvWC4XAoV1+NxnnqiY05g6lqofpE5G02gHezXTjhrGFotqoHf3MIywgwDWa1ixEotvOpOZBjHDLan+IfQCcYhbGm40DrYzYiRT4yEruRnBCUs5DTCW5UcwyPU335G+bDGMv07BBjQx+FzDUEZS7WcNY4VDTWuFI8vWKY49zhQhQ8MwTWgB2QEBt82FdtwUGykElDOieIv4FJGwc8C3Nkie44dKjiBeXSvTVPBOUqiJJnib2I8n+GmwyzP51nbqUIWPYThOPMwJ0Zy3CsQ6qfCfhpgOXiFoF1ShsnqnijfpT5iVOt+AUVF15WYVqPMgAAAABJRU5ErkJggg=="), auto;
    }
  }
  &.container-hover {
    border-top-left-radius: 50vw;
    border-top-right-radius: 50vw;
  }
`;


const CurvedImageMobile = (props) => {

  const { image, link, active } = props;

  return (
    <Container>
      <Inner active={active} bg={image} className={
        active
          ? "container-hover"
          : "container-not-hover"
      }>
        <a href={link}>
          <AspectRatio ratio="325/440" className="aspect"></AspectRatio>
        </a>
      </Inner>

    </Container>
  );
};

export default CurvedImageMobile;
