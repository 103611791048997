import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import CurvedImage from "../curved_image";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import infiniteArray from "infinite-array";
import useWindowSize from "../../hooks/useGatsbyWindowSize";

import ArrowLeft from "../../images/arrow_left.svg";
import ArrowRight from "../../images/arrow_right.svg";

const LeftRightContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 60px;
  z-index: 1;
  display: block;
  @media (max-width: 850px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  width: 50vw;
  cursor:
    url(${ArrowLeft}) 0 16,
    pointer;
`;

const Right = styled.div`
  display: flex;
  height: 100vh;
  width: 50vw;
  position: relative;
  position: absolute;
  right: 0;
  cursor:
    url(${ArrowRight}) 51 16,
    pointer;
`;

const Container = styled.div`
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  opacity: 1;
  display: block;
  @media (max-width: 550px) {
    display: none;
  }

  &:not(:hover) {
    .blurb {
      opacity: 0;
    }
  }

  &:hover {
    .blurb-container {
      opacity: 1;
    }
    .container-not-hover {
      opacity: 0;
      .blurb {
        opacity: 0;
      }
    }
    .container-hover p {
      opacity: 1;
    }
  }
`;

const Blurb = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  border: none;
  margin-top: 20px;
  opacity: 0;
  overflow-y: visible;
  display: block;
  min-height: 80px;
  cursor: pointer;
  pointer-events: none;
  transition: all 1s ease-in-out;
  white-space: pre-line;
  &.h {
    opacity: 0;
  }
  &.v {
    opacity: 1;
  }
  a {
    text-decoration: none;
    color: #000000;
  }
`;

const Blurbs = styled.div`
  display: flex;
  column-gap: 20px;
  opacity: 0;
  transition: all 1s ease-in-out;
  transition-delay: 0.1s;
  pointer-events: none;
  div {
    flex: 1 0;
    opacity: 1;
    transition: all 1s ease-in-out;
  }
`;

const Slide = styled.div`
  transition: all 1s ease-in-out !important;
  transition-delay: 0.5s !important;
  margin-right: 10px;
  margin-left: 10px;
  max-height: 70vh;
  pointer-events: auto;
  position: relative;
`;

const CarouselWide = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityProductindex {
        products {
          title
          blurb
          thumbnail {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
      }
    }
  `);

  const [slidehovered, setSlideHovered] = useState(false);
  const [blurbtext, setBlurbText] = useState(false);
  const [sixth, setSixth] = useState(1);

  const [slideIndex, setSlideIndex] = useState(0);
  const [slide1, setSlide1] = useState(false);
  const [slide2, setSlide2] = useState(false);
  const [slide3, setSlide3] = useState(false);
  const [slide4, setSlide4] = useState(false);
  const [slide5, setSlide5] = useState(false);
  const [slide6, setSlide6] = useState(false);

  const slides = [...data.sanityProductindex.products];

  const nba = infiniteArray(slides);

  useEffect(() => {
    if (slide1 === false) {
      setSlide1(nba[0]);
    } else {
      setSlide1(nba[slideIndex]);
    }

    if (slide2 === false) {
      setSlide2(nba[1]);
    } else {
      setSlide2(nba[slideIndex + 1]);
    }

    if (slide3 === false) {
      setSlide3(nba[2]);
    } else {
      setSlide3(nba[slideIndex + 2]);
    }

    if (slide4 === false) {
      setSlide4(nba[3]);
    } else {
      setSlide4(nba[slideIndex + 3]);
    }

    if (slide5 === false) {
      setSlide5(nba[4]);
    } else {
      setSlide5(nba[slideIndex + 4]);
    }

    if (slide6 === false) {
      setSlide6(nba[5]);
    } else {
      setSlide6(nba[slideIndex + 5]);
    }
  }, [slideIndex]);

  const window_size = useWindowSize();

  const { width } = window_size;

  const handleMouseMove = (event) => {
    const sixths = event.view?.innerWidth ? event.view.innerWidth / 6 : 1;
    const x = parseInt(event.clientX - event.target.offsetLeft);
    if (x > 0 && x < sixths) {
      // 1
      setSixth(1);
    } else if (x >= sixths && x < sixths * 2) {
      // 2
      setSixth(2);
    } else if (x >= sixths * 2 && x < sixths * 3) {
      // 3
      setSixth(3);
    } else if (x >= sixths * 3 && x < sixths * 4) {
      // 4
      setSixth(4);
    } else if (x >= sixths * 4 && x < sixths * 5) {
      // 4
      setSixth(5);
    } else if (x >= sixths * 5 && x < sixths * 6) {
      // 4
      setSixth(6);
    } else if (x >= sixths * 6) {
      // 6 or last
      setSixth(0);
    } else {
      setSixth(0);
    }
  };

  const flickRef = useRef(null);

  const next_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.next();
      setSlideIndex(slideIndex + 1);
    }
  };

  const prev_slide = (e) => {
    if (flickRef.current.animating === false) {
      flickRef.current.prev();
      setSlideIndex(slideIndex - 1);
    }
  };

  return (
    <>
      <LeftRightContainer>
        <Left onClick={(e) => prev_slide(e)}></Left>
        <Right onClick={(e) => next_slide(e)}></Right>
      </LeftRightContainer>

      <Container onMouseMove={handleMouseMove}>
        {width && (
          <Flicking
            align="prev"
            ref={flickRef}
            inputType={["touch"]}
            panelsPerView={6}
            interruptable={false}
            preventClickOnDrag={true}
            duration={1500}
            easing={(x) => 1 - Math.pow(1 - x, 3)}
            circular={true}
          >
            {slides.map((s, index) => {
              return (
                <Slide
                  key={index + 1}
                  className={
                    slidehovered && slidehovered === index + 1
                      ? "container-hover"
                      : "container-not-hover"
                  }
                >
                  <CurvedImage
                    blurb={s.blurb}
                    link={`/${s.slug.current}`}
                    image={s.thumbnail.asset.url}
                    setSlideHovered={setSlideHovered}
                    setBlurbText={setBlurbText}
                    slidehovered={slidehovered}
                    index={index}
                  />
                </Slide>
              );
            })}
          </Flicking>
        )}

        <Blurbs className="blurb-container">
          <div>
            <Blurb className={sixth == 1 ? "blurb v" : "blurb h"}>
              {slide1.blurb}
            </Blurb>
          </div>
          <div>
            <Blurb className={sixth == 2 ? "blurb v" : "blurb h"}>
              {slide2.blurb}
            </Blurb>
          </div>
          <div>
            <Blurb className={sixth == 3 ? "blurb v" : "blurb h"}>
              {slide3.blurb}
            </Blurb>
          </div>
          <div>
            <Blurb className={sixth == 4 ? "blurb v" : "blurb h"}>
              {slide4.blurb}
            </Blurb>
          </div>
          <div>
            <Blurb className={sixth == 5 ? "blurb v" : "blurb h"}>
              {slide5.blurb}
            </Blurb>
          </div>
          <div>
            <Blurb className={sixth == 6 ? "blurb v" : "blurb h"}>
              {slide6.blurb}
            </Blurb>
          </div>
        </Blurbs>
      </Container>
    </>
  );
};

export default CarouselWide;
