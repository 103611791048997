import React from "react";
import styled from "styled-components";
import { AspectRatio } from "react-aspect-ratio";
import { Link } from "gatsby";

const Container = styled.div`
  background-color: #f3f3ed;
  position: relative;
  transition: all 1s ease-in-out;
  pointer-events: none;
  &.hover {
    .blurb {
      opacity: 1;
    }
  }
`;

const Inner = styled.div`
  transition: all 1s ease-in;
  background-image: url("${(props) => props.bg}");
  background-size: cover;
  background-position: center center;
  a {
    pointer-events: auto;
    cursor: ${(props) =>
      props.seen
        ? `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBzZiPVYMwEMa/doKOcBvoBs0GdgPZQDcoGzhCu4E6QdsJ1Alkg7KB3geXFvoHQwng773vPQgpd7lckisThDNTOdPc7sWe5arM9Kba2XU0aGyp2qs2qifVfcUB34dtiWptfVfW1hka/1alZqgNif2WzghuQFQfKEcu6EZqzrSKiuA4+lg4e+djSGexzs+Ij9i73V8dY0fgFE7JHg1TzCRcoX8Y5c2lB6L6QfckDIVOnOXHWvWC4XAoV1+NxnnqiY05g6lqofpE5G02gHezXTjhrGFotqoHf3MIywgwDWa1ixEotvOpOZBjHDLan+IfQCcYhbGm40DrYzYiRT4yEruRnBCUs5DTCW5UcwyPU335G+bDGMv07BBjQx+FzDUEZS7WcNY4VDTWuFI8vWKY49zhQhQ8MwTWgB2QEBt82FdtwUGykElDOieIv4FJGwc8C3Nkie44dKjiBeXSvTVPBOUqiJJnib2I8n+GmwyzP51nbqUIWPYThOPMwJ0Zy3CsQ6qfCfhpgOXiFoF1ShsnqnijfpT5iVOt+AUVF15WYVqPMgAAAABJRU5ErkJggg=="), auto`
        : `auto`};
    &:active {
      cursor: ${(props) =>
        props.seen
          ? `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGdSURBVHgBzZiPVYMwEMa/doKOcBvoBs0GdgPZQDcoGzhCu4E6QdsJ1Alkg7KB3geXFvoHQwng773vPQgpd7lckisThDNTOdPc7sWe5arM9Kba2XU0aGyp2qs2qifVfcUB34dtiWptfVfW1hka/1alZqgNif2WzghuQFQfKEcu6EZqzrSKiuA4+lg4e+djSGexzs+Ij9i73V8dY0fgFE7JHg1TzCRcoX8Y5c2lB6L6QfckDIVOnOXHWvWC4XAoV1+NxnnqiY05g6lqofpE5G02gHezXTjhrGFotqoHf3MIywgwDWa1ixEotvOpOZBjHDLan+IfQCcYhbGm40DrYzYiRT4yEruRnBCUs5DTCW5UcwyPU335G+bDGMv07BBjQx+FzDUEZS7WcNY4VDTWuFI8vWKY49zhQhQ8MwTWgB2QEBt82FdtwUGykElDOieIv4FJGwc8C3Nkie44dKjiBeXSvTVPBOUqiJJnib2I8n+GmwyzP51nbqUIWPYThOPMwJ0Zy3CsQ6qfCfhpgOXiFoF1ShsnqnijfpT5iVOt+AUVF15WYVqPMgAAAABJRU5ErkJggg=="), auto`
          : `auto`};
    }
  }
  &.container-hover:hover {
    border-top-left-radius: 25vw;
    border-top-right-radius: 25vw;
  }
`;

const CurvedImage = (props) => {
  const {
    blurb,
    image,
    seen,
    link,
    index,
    setSlideHovered,
    setBlurbText,
    slidehovered,
  } = props;

  const l = link + "#1";

  const handleHover = (index, blurb) => {
    setSlideHovered(index);
    setBlurbText(blurb);
    // setTimeout(() => {

    // }, 0);
  };

  return (
    <Container
      onMouseEnter={() => handleHover(index + 1, blurb)}
      onMouseLeave={() => handleHover(false, blurb)}
    >
      <Inner
        seen={seen}
        bg={image}
        className={
          slidehovered && slidehovered === index + 1
            ? "container-hover"
            : "container-not-hover"
        }
      >
        <Link to={l}>
          <AspectRatio ratio="325/440" className="aspect"></AspectRatio>
        </Link>
      </Inner>
    </Container>
  );
};

export default CurvedImage;
