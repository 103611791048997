import React from "react";
import CarouselDesktop from '../components/carousel_desktop'
import CarouselWide from '../components/carousel_wide'
import styled from 'styled-components'
import Seo from '../components/seo'
import Helmet from 'react-helmet'
import ProductsMobileNoSlideshow from "../components/products_mobile_noslideshow";
import useWindowSize from '../hooks/useGatsbyWindowSize'

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  @media (max-width: 550px) {
    display: none;
  }
`

const Spacer = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  height: 60px;
  cursor: default;
`

const Products = () => {

  const window_size = useWindowSize()
  const { width } = window_size;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'light noscroll'
        }} />
      <Spacer />
      <Seo title="Products" slug="products" />
      {width > 2000 &&
        <Container>
          <CarouselWide />
        </Container>
      }
      {width > 549 &&
        <Container>
          <CarouselDesktop />
        </Container>
      }
      {width <= 549 &&
        <ProductsMobileNoSlideshow />
      }
    </>
  );
};

export default Products;
